<template>

  <el-button size="small" @click="showDialog" type="primary">
    <el-icon><Setting /></el-icon><span  > 结转科目设置</span>
  </el-button>

  <!-- 新增科目弹窗 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close append-to-body title="科目设置" v-model="dialogVisible" width="50%" >
    

    <div style="margin-bottom:10px">
      <el-radio v-model="info.type" label="simple" border>简易</el-radio>
      <el-radio v-if="type != 'project'" v-model="info.type" label="advanced" border>高级</el-radio>
    </div>

     <div v-if="info.type == 'simple'">
        <el-form ref="dataForm" :model="subjectTemp" label-position="left" label-width="100px">
          <el-form-item  label="计提科目" label-width="100px">
            <span v-if="type == 'project'">工程施工科目设置</span>
            <span v-else>结转科目设置</span>
          </el-form-item>
          <el-form-item :label="type == 'project'?'工程科目':'结转科目'" label-width="100px">
            <subject-list v-model:subjectId="info.subjectId" v-model:fzhsItemId="info.fzhsId" :codes="$findCode(code)"></subject-list>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="info.type == 'simple' && type == 'project'">
        <el-form ref="dataForm" :model="subjectTemp" label-position="left" label-width="100px">
          <el-form-item  label="计提科目" label-width="100px">
            <span>主营业务成本科目设置</span>
          </el-form-item>
          <el-form-item label="成本科目" label-width="100px">
            <subject-list v-model:subjectId="info.cbSubjectId" v-model:fzhsItemId="info.cbFzhsId" :codes="$findCode(['code5401'])"></subject-list>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="info.type == 'advanced'">
        <div></div>

        <!-- 请原材料对应的生产成本科目 -->
        <div style="margin-bottom:10px;float: right; margin-right:25px;margin-top: 10px;">
           <subject-list @success="changeCostCode" v-model:subjectId="form.costSubjectId" v-model:fzhsItemId="form.costFzhsId" :codes="$findCode(code)"></subject-list>
        </div>
        <div class="bigdiv">
          <el-transfer 
          v-if="againShow"
          :titles="['存货及费用', '成本']"
          :props="{
            key: 'sign',
            label: 'name3'
          }" 
          filterable
          v-model="form.signs" 
          :data="tableData">
          </el-transfer>
        </div>
      </div>


      <span v-if="info.type != 'simple'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save()" size="small">保存</el-button>
      </span>


      <span v-else slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save2()" size="small">保存</el-button>
      </span>


    <!-- <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleAddSure" size="small">确 定</el-button>
      </span>
    </template> -->
  </el-dialog>
</template>
<script>

import { inventory1403CostCodeList, inventory1403CostCodeSave, inventoryCarrySettingInfo, inventoryCarrySetting } from "@/api/inventory"

export default {
  props: {
    type: {
      type: String,
      default: "produce",
    },
  },
  data () {
    return {
      dialogVisible: false,
      code: ['code4001','code5401'],
      info: {},
      againShow: true,
      form: {
        costSubjectId: 0,
        costFzhsId: 0,
        type: '',
        signs: [],
      },
      tableData: [
        // {
        //   sign: "1-2",
        //   name: "qqqq"
        // },
        // {
        //   sign: "2-2",
        //   name: "wwww"
        // },
        // {
        //   sign: "3-2",
        //   name: "eeee"
        // },
      ],
    }
  },
  methods: {
    showDialog(){
      inventoryCarrySettingInfo({type: this.type}).then(res=>{
        if(res.data.data.info){
          this.info = res.data.data.info
        } else {
          this.info = {
            type: 'simple'
          }
        }
      })
      this.form.type = this.type

      if(this.form.type == 'produce'){
        this.code = ['code4001']
      } else if(this.form.type == 'trade'){
        this.code = ['code5401']
      } else if(this.form.type == 'process'){
        this.code = ['code1408']
      } else if(this.form.type == 'project'){
        this.code = ['code4001','code4401']
      }
      this.dialogVisible = true
    },
    changeCostCode(){
      inventory1403CostCodeList(this.form).then(res=>{
        if(res.data.data.wbdList){
          this.tableData = res.data.data.wbdList
        }else{
          this.tableData = []
        }
        if(res.data.data.ybdList){
            let value = res.data.data.ybdList.map(v=>{
            return v.sign
          })
          this.form.signs = value
        }else{
          this.form.signs = []
        }
      })
    },
    save(){
      if(this.costSubjectId == 0){
        this.$qzfMessage("请选择结转科目", 1)
        return
      }
      this.save2()
      inventory1403CostCodeSave(this.form).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
        }
      })
    },
    save2(){
      let param = {
        type: this.type,
        simple: this.info.type == 'simple'? 1: 2,
        subjectId: this.info.subjectId,
        fzhsId: this.info.fzhsId,
        cbSubjectId: this.info.cbSubjectId,
        cbFzhsId: this.info.cbFzhsId,
      }
      inventoryCarrySetting(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
        }
      })
    }
  }
}
</script>

<style lang="scss">
.el-checkbox__label{
  overflow: unset;
}
.el-checkbox__label{
  overflow: unset;

}
</style>